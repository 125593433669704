import * as Headless from "@headlessui/react";

// Link as ReactLink from react router dom
import { Link as ReactLink } from "react-router-dom";
import { forwardRef } from "react";
import history from "history";

export const Link = forwardRef(function Link(props, ref) {
  console.log("Link", props, ref);
  if (props.href && props.href.startsWith("http")) {
    return (
      <a {...props} href={props.href} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    );
  } else {
    return (
      <ReactLink {...props} to={props.href}>
        {props.children}
      </ReactLink>
    );
  }
});
