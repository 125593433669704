import React from 'react';
import Dashboard from './Dashboard';
import { Heading } from '../components/heading';
import { Text } from '../components/text';

const ComingSoon = () => {
    return (
        <Dashboard header="Coming Soon">
            <div className="flex flex-col items-center justify-center py-12">
                <Heading level={2} className="mb-4">This Feature is Coming Soon!</Heading>
                <Text className="text-center max-w-md mb-8">
                    We're working hard to bring you this exciting new feature. Stay tuned for updates!
                </Text>
                <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
            </div>
        </Dashboard>
    );
};

export default ComingSoon;