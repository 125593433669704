import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import { Heading } from '../components/heading';
import { Description, Field, FieldGroup, Fieldset, Label, Legend } from '../components/fieldset';
import { Input } from '../components/input';
import { Select } from '../components/select';
import { Strong, Text, TextLink } from '../components/text';
import { Textarea } from '../components/textarea';
import { Switch, SwitchField } from '../components/switch';
import { updateBotSettings } from '../actions';

export class PanelSettings extends Component {


    renderRedirectURL = () => {
        if (this.props.data.bot.dns.custom) {
            return `https://${this.props.data.bot.dns.url}/redirect`;
        } else {
            return `https://${this.props.data.bot.default_domain}/redirect`;

        }
    };
    render() {
        return (
            <Dashboard header="Panel Settings">


                <div className='flex gap-y-10 flex-col'>
                    <section>
                        <form action="/orders" method="POST">
                            {/* ... */}
                            <Fieldset>
                                <Legend>Page Settings</Legend>
                                <Text>Basic bot panel page settings</Text>
                                <FieldGroup>
                                    <Field>
                                        <Label>Title</Label>
                                        <Description>Your panels default page title. If left blank this will be your bots name</Description>
                                        <Input onChange={(e) => {

                                            var dash_settings = { ...this.props.data.bot.dash_settings };
                                            dash_settings.title = e.target.value;
                                            this.props.updateBotSettings('dash_settings', dash_settings);

                                        }} name="street_address" value={this.props.data.bot.dash_settings.title} />
                                    </Field>

                                    <Field>
                                        <Label>Description</Label>
                                        <Description>Your panels default page description. If left blank this will be your bots name</Description>
                                        <Input onChange={(e) => {

                                            var dash_settings = { ...this.props.data.bot.dash_settings };
                                            dash_settings.description = e.target.value;
                                            this.props.updateBotSettings('dash_settings', dash_settings);

                                        }} name="street_address" value={this.props.data.bot.dash_settings.description} />
                                    </Field>

                                    <Field>
                                        <Label>Support Server URL</Label>
                                        <Description>A Discord server invite URL for your bot.</Description>
                                        <Input onChange={(e) => {

                                            var dash_settings = { ...this.props.data.bot.dash_settings };
                                            dash_settings.support = e.target.value;
                                            this.props.updateBotSettings('dash_settings', dash_settings);

                                        }} name="street_address" value={this.props.data.bot.dash_settings.support} />
                                    </Field>

                                </FieldGroup>
                            </Fieldset>
                            {/* ... */}
                        </form>
                    </section>

                    <hr></hr>

                    <section>
                        <form action="/orders" method="POST">
                            {/* ... */}
                            <Fieldset>
                                <Legend>Color Settings</Legend>
                                <Text>Brand colors</Text>
                                <FieldGroup>
                                    <Field>
                                        <Label>Primary</Label>
                                        <Description>The primary color for all buttons and elements. This should be your main brand color</Description>
                                        <Input onChange={(e) => {

                                            var color_settings = { ...this.props.data.bot.color_settings };
                                            color_settings.primary = e.target.value;
                                            this.props.updateBotSettings('color_settings', color_settings);

                                        }} name="street_address" value={this.props.data.bot.color_settings.primary} />
                                    </Field>

                                </FieldGroup>
                            </Fieldset>
                            {/* ... */}
                        </form>
                    </section>

                    <hr></hr>
                    <section>
                        <form action="/orders" method="POST">
                            {/* ... */}
                            <Fieldset>
                                <Legend>Invite Settings</Legend>
                                <Text>Permission settings and redirect options for your Bot's invite URL.</Text>
                                <FieldGroup>
                                    <Field>
                                        <Label>Permissions Integer</Label>
                                        <Description>Which permissions your bot should request when being invited to a server</Description>
                                        <Input onChange={(e) => {

                                            var invite_settings = { ...this.props.data.bot.invite_settings };
                                            invite_settings.invite_perms_int = e.target.value;
                                            this.props.updateBotSettings('invite_settings', invite_settings);

                                        }} name="street_address" type="number" value={this.props.data.bot.invite_settings.invite_perms_int} />
                                    </Field>

                                    <Field>
                                        <SwitchField>
                                            <Label>Invite Redirect</Label>
                                            <Description>Whether to redirect the user to the Bot Panel after they have invited the bot.</Description>
                                            <Switch color="sky" checked={this.props.data.bot.invite_settings.redirect} onChange={(value) => {

                                                var invite_settings = { ...this.props.data.bot.invite_settings };
                                                invite_settings.redirect = value;
                                                this.props.updateBotSettings('invite_settings', invite_settings);
                                            }} name="allow_embedding" value={this.props.data.bot.invite_settings.redirect} />
                                        </SwitchField>
                                        <Description>You must add the redirect url <Strong>{this.renderRedirectURL()}</Strong> to your bot's <TextLink href={`https://discord.com/developers/applications/${this.props.data.bot.id}/oauth2`}>OAuth2 Redirects.</TextLink></Description>

                                    </Field>


                                </FieldGroup>
                            </Fieldset>
                            {/* ... */}
                        </form>
                    </section>
                </div>
                {/* Dashboard Settings (title, description, support URL) */}


                {/* Color Settings */}



                {/* Invite Settings (perms int, redirect) */}
            </Dashboard>
        );
    }
}

const mapStateToProps = (state) => ({
    data: state.data,
});

const mapDispatchToProps = {
    updateBotSettings: updateBotSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelSettings);