import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Avatar } from '../components/avatar';
import { Badge } from '../components/badge';
import toast from "react-hot-toast";

import server from "../utils/server";
import {
    Dropdown,
    DropdownButton,
    DropdownDivider,
    DropdownItem,
    DropdownLabel,
    DropdownMenu,
} from '../components/dropdown';
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from '../components/navbar';
import {
    Sidebar,
    SidebarBody,
    SidebarFooter,
    SidebarHeader,
    SidebarHeading,
    SidebarItem,
    SidebarLabel,
    SidebarSection,
    SidebarSpacer,
} from '../components/sidebar';
import { SidebarLayout } from '../components/sidebar-layout';
import {
    BoltIcon,
    ArrowRightStartOnRectangleIcon,
    BuildingStorefrontIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    Cog8ToothIcon,
    ComputerDesktopIcon,
    GlobeAltIcon,
    LightBulbIcon,
    PlusIcon,
    ShieldCheckIcon,
    UserIcon,
    CogIcon,
    ServerIcon,
    ListBulletIcon,
    ChartBarIcon,
    CurrencyDollarIcon,
    InfinityIcon,
} from '@heroicons/react/16/solid';
import { Alert, AlertActions, AlertDescription, AlertTitle } from '../components/alert';
import { Field, Label } from '../components/fieldset';
import { Input } from '../components/input';
import { Button } from '../components/button';
import {
    Cog6ToothIcon,
    HomeIcon,
    InboxIcon,
    MagnifyingGlassIcon,
    MegaphoneIcon,
    QuestionMarkCircleIcon,
    SparklesIcon,
    Square2StackIcon,
    TicketIcon,
} from '@heroicons/react/20/solid';
import { Heading } from '../components/heading';
import history from '../utils/history';

import { getData, setAuth, updateBotSaveRequired, setLoading } from '../actions';
import { setToken } from '../utils/auth';

export class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bot_timeout: null,
            showDeleteAlert: false,
            deleteName: "",
            disableDeleteButton: false

        };
    }
    componentDidMount() {
        if (!this.props.auth) {
            this.props.setAuth();
            //
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.auth && !prevProps.auth) {
            return this.props.getData();
        } else if (!this.props.auth) {
            var search = window.location.search;
            const params = new URLSearchParams(search);
            const token = params.get("token");
            if (token) {
                setToken(token);
                // history.push("/dashboard");
            } else {
                this.props.setAuth();
            }


        }

        if (this.props.loaded && this.props.bots.length == 0) {
            history.push('/panel/new');
        }

        if (
            this.props.saves_required.bot != null &&
            prevProps.saves_required.bot !=
            this.props.saves_required.bot
        ) {
            // Clear the timeout if it exists.
            if (this.state.bot_timeout) {
                clearTimeout(this.state.bot_timeout);
            }
            // Create a timeout to save the server settings in 3 seconds.
            var bot_timeout = setTimeout(() => {
                // this.props.getData();
                this.saveBot();
            }, 1500);
            this.setState({ bot_timeout });
        }
    }

    saveBot = async () => {
        var response = await server.post("/dashboard/bot/save", {
            bot: this.props.bot,
        });
        // await server
        //     .post(
        //         `/client/server/${this.props.active_server.id}/server_settings`,
        //         this.props.data.serverSettings
        //     )
        //     .catch((e) => {
        //         //
        //     });

        toast.success("Settings Saved!", {
            // icon: "",
            style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
            },
        });

        this.props.updateBotSaveRequired();

        // console.log("Saving Server Settings");
        // await this.props.saveServerSettings();
        // this.props.getData();
    };

    renderBotDropDown = () => {
        var bots = [];
        this.props.bots.forEach(bot => {
            if (bot.id == this.props.bot.id) return;
            bots.push(
                <DropdownItem key={bot.id} onClick={async () => {
                    this.props.setLoading(false);
                    await server.get(`/dashboard/bot/switch/${bot.id}`);
                    this.props.getData();
                }}>
                    <Avatar slot="icon" src={bot.img} />
                    <DropdownLabel>{bot.name}</DropdownLabel>
                    {this.renderSubscriptionBadge(bot.subscription.type)}
                </DropdownItem>
            );
        });

        return bots;
    };

    renderSubscriptionBadge = (type) => {
        const { user } = this.props;
        const isUnlimited = user && user.unlimited;

        switch (type) {
            case 'basic':
                return (
                    <Badge color="blue">
                        Basic
                    </Badge>
                );
            case 'hobby':
                return (
                    <Badge color="green">
                        Hobby
                    </Badge>
                );
            case 'pro':
                return (
                    <Badge color="purple">
                        Pro
                    </Badge>
                );
            default:
                return (
                    <Badge color="gray">
                        Free
                    </Badge>
                );
        }
    };

    renderLinkURL = () => {
        if (this.props.bot.dns.custom && this.props.bot.dns.status == 'active') {
            return `https://${this.props.bot.dns.url}`;
        } else {
            return `https://${this.props.bot.id}.${process.env.REACT_APP_ROOT_NAME}`;
        }
    };
    render() {
        console.log(this.props.auth, this.props.loaded, 'HERE');
        if (this.props.auth && this.props.loaded && this.props.bots.length > 0) {
            return (
                <>
                    <>
                        <div className="fixed right-0 bottom-0 z-10">
                            <div
                                className="p-3 bg-darkGray rounded-t-md flex items-center"
                                onClick={() => {
                                    window.open("https://botpanel.gg", "_blank");
                                }}
                            >
                                <img src={process.env.PUBLIC_URL + "/img/logo.png"} className="h-5 mr-1"></img>
                                <span className="text-xs font-bold hover:underline hover:cursor-pointer text-white">BotPanel Beta</span>
                            </div>
                        </div>
                    </>
                    <Alert open={this.state.showDeleteAlert} onClose={() => {
                        this.setState({ showDeleteAlert: false });
                    }}>
                        <AlertTitle>Are you sure you want to delete this Bot's Panel?</AlertTitle>
                        <AlertDescription>
                            This action is irreversible and will delete all data associated with this bot. This includes all settings, modules, and analytics data. Type the Bot's name below to confirm.

                            <Field className={"my-5"}>
                                <Label>Bot Name</Label>
                                <Input placeholder={this.props.bot.name} value={this.state.deleteName} onChange={(e) => {
                                    this.setState({ deleteName: e.target.value });
                                }} name="full_name" />
                                <Label className={"opacity-50 mt-2"}>{this.props.bot.name}</Label>
                            </Field>
                        </AlertDescription>
                        <AlertActions>
                            <Button plain onClick={() => {
                                this.setState({ showDeleteAlert: false });
                            }}>
                                Cancel
                            </Button>
                            <Button color="red" disabled={
                                this.state.deleteName != this.props.bot.name || this.state.disableDeleteButton
                            } onClick={async () => {
                                this.setState({ disableDeleteButton: true });
                                await server.delete(`/dashboard/bot/delete/${this.props.bot.id}`);
                                window.location.href = '/';
                            }}>Delete</Button>
                        </AlertActions>
                    </Alert>
                    <SidebarLayout
                        navbar={
                            <Navbar>
                                <NavbarSpacer />
                                <NavbarSection>
                                    <NavbarItem href={this.renderLinkURL()} aria-label="Search">
                                        <GlobeAltIcon />
                                    </NavbarItem>
                                    <NavbarItem className="text-blue-500 animate-pulse" href="/pro" aria-label="Inbox">
                                        <CurrencyDollarIcon color="blue-500" className='fill-blue-500' />
                                    </NavbarItem>
                                    <Dropdown>
                                        <DropdownButton as={NavbarItem}>
                                            <Avatar src={`https://cdn.discordapp.com/avatars/${this.props.user.id}/${this.props.user.img}.png?size=1024`} square />
                                        </DropdownButton>
                                        <DropdownMenu className="min-w-64" anchor="bottom end">
                                            {this.props.bot?.subscription?.type !== 'free' && (
                                                <DropdownItem href={`${process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL}?prefilled_email=${this.props.user.email}`}>
                                                    <CogIcon />
                                                    <DropdownLabel>Manage Subscription</DropdownLabel>
                                                </DropdownItem>
                                            )}
                                            <DropdownItem onClick={() => {
                                                this.setState({ showDeleteAlert: true });
                                            }}>
                                                <UserIcon />
                                                <DropdownLabel>Delete Bot</DropdownLabel>
                                            </DropdownItem>

                                            <DropdownDivider />
                                            <DropdownItem onClick={async () => {
                                                await server.get('/logout');
                                                window.location.href = '/';
                                            }}>
                                                <ArrowRightStartOnRectangleIcon />
                                                <DropdownLabel>Sign out</DropdownLabel>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </NavbarSection>
                            </Navbar>
                        }
                        sidebar={
                            <Sidebar>
                                <SidebarHeader>
                                    <Dropdown>
                                        <DropdownButton as={SidebarItem} className="lg:mb-2.5">
                                            <Avatar src={this.props.bot.img} />
                                            <SidebarLabel>{this.props.bot.name}</SidebarLabel>
                                            {this.renderSubscriptionBadge(this.props.bot.subscription.type)}
                                            <ChevronDownIcon />
                                        </DropdownButton>
                                        <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
                                            {this.renderBotDropDown()}
                                            {this.props.bots.length > 1 ? <DropdownDivider /> : null}
                                            <DropdownItem href="/panel/new">
                                                <PlusIcon />
                                                <DropdownLabel>New Bot</DropdownLabel>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>


                                    <SidebarSection className="max-lg:hidden">
                                        <SidebarItem href="/search">
                                            <HomeIcon />
                                            <SidebarLabel>Home</SidebarLabel>
                                        </SidebarItem>

                                        <SidebarItem className="text-pink-400" href="/pro">
                                            <BoltIcon color="blue" className='stroke-blue-500' />
                                            <SidebarLabel className='text-blue-500'>BotPanel Pro</SidebarLabel>
                                        </SidebarItem>

                                        <SidebarItem href={this.renderLinkURL()} >
                                            <ArrowRightStartOnRectangleIcon />
                                            <SidebarLabel>Live BotPanel</SidebarLabel>
                                        </SidebarItem>


                                    </SidebarSection>
                                </SidebarHeader>



                                <SidebarBody>
                                    {/* <SidebarSection>
                                    <SidebarItem href="/">
                                        <HomeIcon />
                                        <SidebarLabel>Home</SidebarLabel>
                                    </SidebarItem>
                                    <SidebarItem href="/events">
                                        <Square2StackIcon />
                                        <SidebarLabel>Events</SidebarLabel>
                                    </SidebarItem>
                                    <SidebarItem href="/orders">
                                        <TicketIcon />
                                        <SidebarLabel>Orders</SidebarLabel>
                                    </SidebarItem>
                                    <SidebarItem href="/settings">
                                        <Cog6ToothIcon />
                                        <SidebarLabel>Settings</SidebarLabel>
                                    </SidebarItem>
                                    <SidebarItem href="/broadcasts">
                                        <MegaphoneIcon />
                                        <SidebarLabel>Broadcasts</SidebarLabel>
                                    </SidebarItem>
                                </SidebarSection> */}


                                    <SidebarSection className="">
                                        <SidebarHeading>Panel Options</SidebarHeading>
                                        <SidebarItem href="/panel/settings">
                                            <Cog6ToothIcon />
                                            <SidebarLabel>General Settings</SidebarLabel>
                                        </SidebarItem>
                                        <SidebarItem href="/panel/dns">
                                            <GlobeAltIcon />
                                            <SidebarLabel>DNS</SidebarLabel>
                                        </SidebarItem>

                                        <SidebarItem href="/panel/modules">
                                            <BuildingStorefrontIcon />
                                            <SidebarLabel>Bot Modules</SidebarLabel>
                                        </SidebarItem>
                                        {/* <SidebarItem href="/events/4">We All Look The Same</SidebarItem> */}
                                    </SidebarSection>

                                    <SidebarSection className="">
                                        <SidebarHeading>Homepage Options</SidebarHeading>
                                        <SidebarItem href="/panel/coming-soon">Coming Soon!</SidebarItem>
                                        {/* <SidebarItem href="/events/2">DNS</SidebarItem>
                                    <SidebarItem href="/events/3">Bot Modules</SidebarItem> */}
                                        {/* <SidebarItem href="/events/4">We All Look The Same</SidebarItem> */}
                                    </SidebarSection>

                                    <SidebarSection className="">
                                        <SidebarHeading>Analytics</SidebarHeading>
                                        <SidebarItem href="/panel/coming-soon">
                                            <ChartBarIcon />
                                            <SidebarLabel>Analytics</SidebarLabel>
                                        </SidebarItem>
                                        <SidebarItem href="/servers">
                                            <ListBulletIcon />
                                            <SidebarLabel>Servers</SidebarLabel>
                                        </SidebarItem>
                                        {/* <SidebarItem href="/events/2">DNS</SidebarItem>
                                    <SidebarItem href="/events/3">Bot Modules</SidebarItem> */}
                                        {/* <SidebarItem href="/events/4">We All Look The Same</SidebarItem> */}
                                    </SidebarSection>

                                    <SidebarSection className="">
                                        <SidebarHeading>Monetization</SidebarHeading>
                                        <SidebarItem href="/panel/coming-soon">Coming Soon!</SidebarItem>
                                        {/* <SidebarItem href="/events/2">DNS</SidebarItem>
                                    <SidebarItem href="/events/3">Bot Modules</SidebarItem> */}
                                        {/* <SidebarItem href="/events/4">We All Look The Same</SidebarItem> */}
                                    </SidebarSection>


                                    <SidebarSpacer />
                                    <SidebarSection>
                                        <SidebarItem className="hover:cursor-pointer" onClick={() => {
                                            // Open "https://discord.gg/botghost" in a new tab
                                            window.open('https://discord.gg/botghost', '_blank');
                                        }}>
                                            <QuestionMarkCircleIcon />
                                            <SidebarLabel>Support</SidebarLabel>
                                        </SidebarItem>
                                        {/* <SidebarItem href="/changelog">
                                            <SparklesIcon />
                                            <SidebarLabel>Changelog</SidebarLabel>
                                        </SidebarItem> */}
                                    </SidebarSection>
                                </SidebarBody>
                                <SidebarFooter className="max-lg:hidden">
                                    <Dropdown>
                                        <DropdownButton as={SidebarItem}>
                                            <span className="flex min-w-0 items-center gap-3">
                                                <Avatar src={`https://cdn.discordapp.com/avatars/${this.props.user.id}/${this.props.user.img}.png?size=1024`} className="size-10" square alt="" />
                                                <span className="min-w-0">
                                                    <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">{this.props.user.name}</span>
                                                    <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                                                        {this.props.user.email}
                                                    </span>
                                                </span>
                                            </span>
                                            <ChevronUpIcon />
                                        </DropdownButton>
                                        <DropdownMenu className="min-w-64" anchor="top start">
                                            {this.props.bot?.subscription?.type !== 'free' && (
                                                <DropdownItem href={`${process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL}?prefilled_email=${this.props.user.email}`}>
                                                    <CogIcon />
                                                    <DropdownLabel>Manage Subscription</DropdownLabel>
                                                </DropdownItem>
                                            )}
                                            <DropdownItem onClick={() => {
                                                this.setState({ showDeleteAlert: true });
                                            }}>
                                                <UserIcon />
                                                <DropdownLabel>Delete Bot</DropdownLabel>
                                            </DropdownItem>

                                            <DropdownDivider />
                                            <DropdownItem onClick={async () => {
                                                await server.get('/logout');
                                                window.location.href = '/';
                                            }}>
                                                <ArrowRightStartOnRectangleIcon />
                                                <DropdownLabel>Sign out</DropdownLabel>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </SidebarFooter>
                            </Sidebar>
                        }
                    // children={<>{this.props.children}</>}
                    >
                        {/* The page content */}


                        <div>
                            {this.props.header ?
                                <>
                                    <Heading>{this.props.header}</Heading>
                                    <hr role="presentation" class="mt-6 w-full border-t border-zinc-950/10 dark:border-white/10" />
                                </> : null}
                            {/* <hr></hr> */}
                            <div className='py-6 mx-auto max-w-6xl'>
                                {this.props.children}
                            </div>
                        </div>

                    </SidebarLayout>
                </>

            );
        } else {
            return (
                <div className="flex justify-center items-center h-screen h-5 bg-zinc-900">
                    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-white"></div>
                </div>
            );
        }



    }
}
const mapStateToProps = (state) => ({
    auth: state.auth,
    loaded: state.data.loaded,
    user: state.data.user,
    bot: state.data.bot,
    bots: state.data.bots,
    saves_required: state.data.saves_required,
});

const mapDispatchToProps = {
    setAuth: setAuth,
    getData: getData,
    updateBotSaveRequired: updateBotSaveRequired,
    setLoading: setLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);