import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, Router } from "react-router-dom";

import { getData, setAuth } from "./actions";
import { Helmet } from "react-helmet";

import { Toaster } from "react-hot-toast";
import { Button } from "./components/button";
import Home from "./pages/Home";
import history from "./utils/history";
import DNS from "./pages/DNS";
import Pricing from "./pages/Pricing";
import PanelSettings from "./pages/PanelSettings";
import ModuleSettings from "./pages/ModuleSettings";
import NewBot from "./pages/NewBot";
import Servers from "./pages/Servers";
import AuthCallback from "./components/AuthCallback";

import { useLocation, useHistory } from "react-router-dom";
import { setToken } from "./utils/auth";
import ComingSoon from "./pages/ComingSoon";
const Main = () => <h1>Hello world</h1>;

function hideError(e) {
  if (e.message === "ResizeObserver loop completed with undelivered notifications.") {
    const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
    const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
    if (resizeObserverErr) {
      resizeObserverErr.setAttribute("style", "display: none");
    }
    if (resizeObserverErrDiv) {
      resizeObserverErrDiv.setAttribute("style", "display: none");
    }
  }
}
export class App extends Component {
  componentDidMount() {
    window.addEventListener("error", (e) => {
      hideError(e);
    });
  }
  render() {
    if (!this.props.loaded) {
      return (
        <>
          <div className="flex justify-center items-center h-screen">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Toaster position="top-right" />

          {/* <Toaster position="top-right" />
          <Helmet>
            <title>{this.props.bot.dash_settings.title}</title>
            <description>{this.props.bot.dash_settings?.description}</description>
            {/* Favicon */}
          {/* <link rel="icon" type="image/png" sizes="16x16" href={this.props.bot.img}></link> */}
          {/* </Helmet> */}

          {/* <Button>Save changes</Button> */}
          <Router history={history}>
            <Switch>
              <Route path="/auth/callback" component={AuthCallback} />
              <Route path="/panel/settings" component={PanelSettings} />
              <Route path="/panel/new" component={NewBot} />
              <Route path="/panel/dns" component={DNS} />
              <Route path="/panel/modules" component={ModuleSettings} />
              <Route path="/panel/coming-soon" component={ComingSoon} />
              <Route path="/servers" component={Servers} />
              <Route path="/pro" component={Pricing} />
              <Route path="/auth/test" component={"Hello"} />
              <Route path="/" component={Home} />
            </Switch>
          </Router>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  loaded: true,
  bot: state.data.bot,
});

const mapDispatchToProps = {
  getData,
  setAuth,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
