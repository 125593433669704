import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import { CheckIcon, MinusIcon } from '@heroicons/react/20/solid';
import server from '../utils/server';
import { Switch, SwitchField } from '../components/switch';
import { Fragment } from 'react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const tiers = [
    {
        name: 'Free',
        id: 'free',
        href: '#',
        priceMonthly: '$0',
        priceYearly: '$0',
        description: 'Good for small Discord servers and those just getting started.',
        mostPopular: false,
    },
    {
        name: 'Basic',
        id: 'basic',
        href: 'https://buy.stripe.com/test_9AQg1y8Xj3o4aT6146',
        priceMonthly: `$${process.env.REACT_APP_BASIC_PRICE_AMOUNT}`,
        priceYearly: `$${process.env.REACT_APP_BASIC_PRICE_YEARLY_AMOUNT}`,
        description: 'Perfect for growing Discord Bot\'s.',
        mostPopular: false,
        links: {
            monthly: "https://buy.stripe.com/test_9AQg1y8Xj3o4aT6146",
            yearly: "https://buy.stripe.com/yearly_test_9AQg1y8Xj3o4aT6146"
        },
        priceId: {
            monthly: process.env.REACT_APP_BASIC_PRICE,
            yearly: process.env.REACT_APP_BASIC_PRICE_YEARLY
        }
    },
    {
        name: 'Hobby',
        id: 'hobby',
        href: '#',
        priceMonthly: `$${process.env.REACT_APP_HOBBY_PRICE_AMOUNT}`,
        priceYearly: `$${process.env.REACT_APP_HOBBY_PRICE_YEARLY_AMOUNT}`,
        description: 'Made for established and growing bot developers',
        mostPopular: true,
        links: {
            monthly: "https://buy.stripe.com/test_9AQg1y8Xj3o4aT6146",
            yearly: "https://buy.stripe.com/yearly_test_9AQg1y8Xj3o4aT6146"
        },
        priceId: {
            monthly: process.env.REACT_APP_HOBBY_PRICE,
            yearly: process.env.REACT_APP_HOBBY_PRICE_YEARLY
        }
    },
    {
        name: 'Professional',
        id: 'pro',
        href: '#',
        priceMonthly: `$${process.env.REACT_APP_PRO_PRICE_AMOUNT}`,
        priceYearly: `$${process.env.REACT_APP_PRO_PRICE_YEARLY_AMOUNT}`,
        description: 'For professional bot developers looking for a platform to grow their bot\'s presence.',
        mostPopular: false,
        links: {
            monthly: "https://buy.stripe.com/test_9AQg1y8Xj3o4aT6146",
            yearly: "https://buy.stripe.com/yearly_test_9AQg1y8Xj3o4aT6146"
        },
        priceId: {
            monthly: process.env.REACT_APP_PRO_PRICE,
            yearly: process.env.REACT_APP_PRO_PRICE_YEARLY
        }
    },
];
const sections = [
    {
        name: 'Basics',
        features: [
            { name: 'Servers Supported', tiers: { Free: 'Up to 10 servers', Basic: 'Up to 25 servers', Hobby: 'Up to 50 servers', Professional: 'Unlimited servers' } },

            { name: 'Advertising free', tiers: { Free: false, Basic: true, Hobby: true, Professional: true } },
            { name: 'Basic Stats (Views, Servers)', tiers: { Free: true, Basic: true, Hobby: true, Professional: true } },
            { name: 'Dashboard', tiers: { Free: true, Basic: true, Hobby: true, Professional: true } },
        ],
    },
    {
        name: 'Dashboard',
        features: [
            { name: 'Basic Configurable Bot Dashboard', tiers: { Free: true, Basic: true, Hobby: true, Professional: true } },
            { name: 'Server specific Bot Module Settings', tiers: { Free: true, Basic: true, Hobby: true, Professional: true } },
            { name: 'Edit General Settings', tiers: { Free: true, Basic: true, Hobby: true, Professional: true } },
            { name: 'Create Custom Pages', tiers: { Hobby: true, Professional: true } },
        ],
    },
    {
        name: 'Bot Modules',
        features: [
            { name: 'Enable/Disable BotGhost Custom Modules', tiers: { Free: true, Basic: true, Hobby: true, Professional: true } },
            { name: 'Create Custom Modules', tiers: { Professional: true } },
        ],
    },
    {
        name: 'DNS',
        features: [
            { name: 'BotPanel Domain', tiers: { Free: true, Basic: true, Hobby: true, Professional: true } },
            { name: 'Custom Domain', tiers: { Basic: true, Hobby: true, Professional: true } },
        ],
    },
    {
        name: 'Home Page',
        features: [
            { name: 'Basic Landing Page', tiers: { Free: true, Basic: true, Hobby: true, Professional: true } },
            { name: 'Custom Pages', tiers: { Hobby: true, Professional: true } },
            { name: 'Advanced Home page with Home Page Builder', tiers: { Hobby: true, Professional: true } },
        ],
    },
    {
        name: 'Analytics',
        features: [
            { name: 'Advanced usage analytics', tiers: { Basic: true, Hobby: true, Professional: true } },
            { name: 'Command usage', tiers: { Basic: true, Hobby: true, Professional: true } },
            { name: 'Module Usage', tiers: { Hobby: true, Professional: true } },
            { name: 'Collect Website Feedback', tiers: { Hobby: true, Professional: true } },
            { name: 'Collect Bot Feedback', tiers: { Hobby: true, Professional: true } },
        ],
    },
    {
        name: 'Monetization',
        features: [
            { name: 'Collect Payments for your Bot', tiers: { Professional: true } },
        ],
    },
];

const addon = {
    name: "Unlimited Bot Panels",
    priceMonthly: `+$10`,
    priceYearly: `+$40`,
    description: "Create and manage unlimited bot panels. Perfect for agencies or developers with multiple bots.",
    priceId: {
        monthly: process.env.REACT_APP_UNLIMITED_BOT_PANELS_MONTHLY,
        yearly: process.env.REACT_APP_UNLIMITED_BOT_PANELS_YEARLY
    }
};

export class Pricing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            frequency: 'monthly',
            loadingTier: null,
            addonSelected: false,
        };
    }

    handlePurchase = async (tier) => {
        this.setState({ loadingTier: tier.id });
        try {
            const checkout = await server.post("/dashboard/checkout/create", {
                price_id: tier.priceId[this.state.frequency],
                bot_id: this.props.bot.id,
                customer_id: this.props.user.customer_id,
                addon: this.state.addonSelected ? addon.priceId[this.state.frequency] : false
            });

            if (checkout && checkout.data.success && checkout.data.url) {
                window.location.href = checkout.data.url;
            }
        } catch (e) {
            // Handle error
        }
        this.setState({ loadingTier: null });
    };

    toggleAddon = () => {
        this.setState(prevState => ({ addonSelected: !prevState.addonSelected }));
    };

    toggleFrequency = () => {
        this.setState(prevState => ({
            frequency: prevState.frequency === 'monthly' ? 'yearly' : 'monthly'
        }));
    };

    render() {
        return (
            <Dashboard>
                <div className=" py-4">
                    <div className=" px-6 lg:px-8">
                        <div className="mx-auto max-w-4xl text-center">
                            <h2 className="text-base font-semibold leading-7 text-indigo-400">Pricing</h2>
                            <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
                                Simple pricing options for every bot developer
                            </p>
                        </div>
                        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
                            Choose the perfect plan for your bot management needs.
                        </p>

                        {/* Billing frequency toggle */}
                        <div className="mt-12 flex justify-center">
                            <div className="relative flex items-center justify-center p-1 rounded-full">
                                <span className={`${this.state.frequency === 'monthly' ? 'text-white' : 'text-gray-500'} mr-3 text-sm font-medium`}>Monthly</span>
                                <SwitchField className="!flex mx-4 justify-center">

                                    <Switch
                                        color="blue"
                                        size="xl"
                                        checked={this.state.frequency === 'yearly'}
                                        onChange={this.toggleFrequency}
                                        name="billing-toggle"
                                        className="relative inline-flex h-8 w-16 items-center rounded-full"
                                    />
                                </SwitchField>
                                <span className={`${this.state.frequency === 'yearly' ? 'text-white' : 'text-gray-500'} ml-3 text-sm font-medium`}>Yearly</span>
                            </div>
                        </div>T

                        {/* Pricing tiers */}
                        <div className="mt-16 grid grid-cols-1 gap-y-8 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                            {tiers.map((tier) => (
                                <div key={tier.id} className={classNames(
                                    tier.mostPopular ? 'ring-2 ring-indigo-500' : 'ring-1 ring-white/10',
                                    'rounded-3xl p-8 xl:p-10 flex flex-col'
                                )}>
                                    <div className="mb-auto">
                                        <div className="flex items-center justify-between gap-x-4">
                                            <h3 className="text-lg font-semibold leading-8 text-white">{tier.name}</h3>
                                            {tier.mostPopular ? (
                                                <p className="rounded-full bg-indigo-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                                                    Most popular
                                                </p>
                                            ) : null}
                                        </div>
                                        <p className="mt-4 text-sm leading-6 text-gray-300">{tier.description}</p>
                                        <div className="mt-6 flex items-baseline gap-x-1">
                                            <span className="text-4xl font-bold tracking-tight text-white">
                                                {this.state.frequency === 'monthly' ? tier.priceMonthly : tier.priceYearly}
                                            </span>
                                            <span className="text-sm font-semibold leading-6 text-gray-300">
                                                /{this.state.frequency === 'monthly' ? 'month' : 'year'}
                                            </span>
                                        </div>
                                    </div>
                                    <button
                                        onClick={() => this.handlePurchase(tier)}
                                        disabled={this.props.bot?.subscription?.type === tier.id || this.state.loadingTier === tier.id}
                                        className={classNames(
                                            tier.mostPopular
                                                ? 'bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500'
                                                : 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white',
                                            'mt-6 block w-full rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                                        )}
                                    >
                                        {this.state.loadingTier === tier.id ? 'Loading...' : this.props.bot?.subscription?.type === tier.id ? 'Current Plan' : 'Upgrade'}
                                    </button>
                                </div>
                            ))}
                        </div>

                        {/* Addon section */}
                        <div className="mt-16 rounded-3xl bg-white/5 p-8 ring-1 ring-inset ring-white/10 sm:p-10">
                            <div className="flex items-center justify-between">
                                <div>
                                    <h3 className="text-sm font-semibold leading-6 text-white">{addon.name}</h3>
                                    <p className="mt-2 text-sm text-gray-300">{addon.description}</p>
                                    <p className="mt-4 flex items-baseline gap-x-1">
                                        <span className="text-4xl font-bold tracking-tight text-white">
                                            {this.state.frequency === 'monthly' ? addon.priceMonthly : addon.priceYearly}
                                        </span>
                                        <span className="text-sm font-semibold leading-6 text-gray-300">
                                            /{this.state.frequency === 'monthly' ? 'month' : 'year'}
                                        </span>
                                    </p>
                                </div>
                                <div className="flex items-center">
                                    <SwitchField>
                                        <Switch
                                            color="blue"
                                            size="xl"
                                            checked={this.state.addonSelected}
                                            onChange={this.toggleAddon}
                                            name="addon-toggle"
                                            value={this.state.addonSelected}
                                        />
                                    </SwitchField>
                                </div>
                            </div>
                        </div>

                        {/* Feature comparison table */}
                        <div className="isolate mt-20 hidden lg:block">
                            <div className="relative -mx-8">
                                {tiers.some((tier) => tier.mostPopular) ? (
                                    <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
                                        <div
                                            style={{ marginLeft: `${(tiers.findIndex((tier) => tier.mostPopular) + 1) * 20}%` }}
                                            aria-hidden="true"
                                            className="flex w-1/5 px-4"
                                        >
                                            <div className="w-full rounded-t-xl border-x border-t border-white/10 bg-white/5" />
                                        </div>
                                    </div>
                                ) : null}
                                <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
                                    <caption className="sr-only">Pricing plan comparison</caption>
                                    <colgroup>
                                        <col className="w-1/5" />
                                        <col className="w-1/5" />
                                        <col className="w-1/5" />
                                        <col className="w-1/5" />
                                        <col className="w-1/5" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <td />
                                            {tiers.map((tier) => (
                                                <th key={tier.id} scope="col" className="px-6 pt-6 xl:px-8 xl:pt-8">
                                                    <div className="text-sm font-semibold leading-7 text-white">{tier.name}</div>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">
                                                <span className="sr-only">Price</span>
                                            </th>
                                            {tiers.map((tier) => (
                                                <td key={tier.id} className="px-6 pt-2 xl:px-8">
                                                    <div className="flex items-baseline gap-x-1 text-white">
                                                        <span className="text-4xl font-bold">
                                                            {this.state.frequency === 'monthly' ? tier.priceMonthly : tier.priceYearly}
                                                        </span>
                                                        <span className="text-sm font-semibold leading-6">
                                                            /{this.state.frequency === 'monthly' ? 'month' : 'year'}
                                                        </span>
                                                    </div>
                                                    <button
                                                        disabled={
                                                            this.props.bot?.subscription?.type == tier.id ||
                                                            this.state.loadingTier === tier.id
                                                        }
                                                        onClick={async () => {
                                                            this.setState({ loadingTier: tier.id });
                                                            var checkout = await server.post("/dashboard/checkout/create", {
                                                                price_id: tier.priceId[this.state.frequency],
                                                                bot_id: this.props.bot.id,
                                                                customer_id: this.props.user.customer_id
                                                            }).catch(e => {
                                                                // Handle error
                                                                this.setState({ loadingTier: null });
                                                            });

                                                            if (checkout && checkout.data.success && checkout.data.url) {
                                                                window.location.href = checkout.data.url;
                                                            } else {
                                                                this.setState({ loadingTier: null });
                                                            }
                                                        }}
                                                        className={classNames(
                                                            tier.mostPopular
                                                                ? 'bg-indigo-500 hover:bg-indigo-400 focus-visible:outline-indigo-600'
                                                                : 'bg-white/10 hover:bg-white/20 focus-visible:outline-white',
                                                            'mt-8 w-full block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
                                                        )}
                                                    >
                                                        {this.state.loadingTier === tier.id ? (
                                                            <svg className="animate-spin h-5 w-5 mx-auto text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                        ) : this.props.bot?.subscription?.type == tier.id ? (
                                                            'Current'
                                                        ) : this.props.bot?.subscription?.type !== 'free' ? (
                                                            'Change'
                                                        ) : (
                                                            'Upgrade'
                                                        )}
                                                    </button>
                                                </td>
                                            ))}
                                        </tr>
                                        {sections.map((section, sectionIdx) => (
                                            <Fragment key={section.name}>
                                                <tr>
                                                    <th
                                                        scope="colgroup"
                                                        colSpan={4}
                                                        className={classNames(
                                                            sectionIdx === 0 ? 'pt-8' : 'pt-16',
                                                            'pb-4 text-sm font-semibold leading-6 text-white',
                                                        )}
                                                    >
                                                        {section.name}
                                                        <div className="absolute inset-x-8 mt-4 h-px bg-white/10" />
                                                    </th>
                                                </tr>
                                                {section.features.map((feature) => (
                                                    <tr key={feature.name}>
                                                        <th scope="row" className="py-4 text-sm font-normal leading-6 text-white">
                                                            {feature.name}
                                                            <div className="absolute inset-x-8 mt-4 h-px bg-white/5" />
                                                        </th>
                                                        {tiers.map((tier) => (
                                                            <td key={tier.id} className="px-6 py-4 xl:px-8">
                                                                {typeof feature.tiers[tier.name] === 'string' ? (
                                                                    <div className="text-center text-sm leading-6 text-gray-300">
                                                                        {feature.tiers[tier.name]}
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        {feature.tiers[tier.name] === true ? (
                                                                            <CheckIcon aria-hidden="true" className="mx-auto h-5 w-5 text-indigo-400" />
                                                                        ) : (
                                                                            <MinusIcon aria-hidden="true" className="mx-auto h-5 w-5 text-gray-500" />
                                                                        )}

                                                                        <span className="sr-only">
                                                                            {feature.tiers[tier.name] === true ? 'Included' : 'Not included'} in {tier.name}
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Dashboard>
        );
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot || {
        subscription: {
            type: 'free'
        }
    },
    user: state.data.user || {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Pricing);