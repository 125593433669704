import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/table';
import { Button } from '../components/button';
import { Alert, AlertActions, AlertDescription, AlertTitle } from '../components/alert';
import { updateBotSettings } from '../actions';
import moment from 'moment';
import server from '../utils/server';
import { Heading } from '../components/heading';


const SERVER_LIMITS = {
    free: 10,
    basic: 25,
    hobby: 50,
    pro: Infinity // Using Infinity for unlimited servers
};


export class Servers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            servers: [],
            loaded: false,
            deleteId: null,
            openAlert: false,
            disableButton: false
        };


    }

    componentDidMount() {
        if (this.props.bot_id) {
            this.getServers();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.bot_id && this.props.bot_id !== prevProps.bot_id) {
            this.getServers();
        }
    }

    getServers = async () => {
        var response = await server.get("/dashboard/servers/" + this.props.bot_id).catch(e => {

        });

        if (response.data) {
            this.setState({
                servers: response.data,
                loaded: true
            });
        }

    };
    renderServers = () => {
        var servers = [];
        this.state.servers.forEach((server, index) => {
            servers.push(
                <TableRow key={server.id}>
                    <TableCell className="font-medium">{server.id}</TableCell>
                    <TableCell>{moment(server.last_update).fromNow()}</TableCell>
                    <TableCell className="text-zinc-500">
                        <Button color="red" onClick={async () => {
                            // await server.delete("/dashboard/servers/" + this.props.bot_id + "/" + server.id);
                            this.setState({
                                deleteId: server.id,
                                openAlert: true
                            });

                            // Remove from array

                        }}>Delete</Button>
                    </TableCell>
                </TableRow>
            );
        });


        return servers;
    };

    renderServerCount = () => {
        if (!this.state.loaded) return null;

        var count = this.state.servers.length;
        var limit = SERVER_LIMITS[this.props.bot.subscription.type];
        if (count >= limit) return <span className="text-red-500">({count}/{limit === Infinity ? '∞' : limit})</span>;
        return <span className="text-zinc-500">({count}/{limit === Infinity ? '∞' : limit})</span>;

    };
    render() {
        return (
            <Dashboard >
                <div className='border-zinc-950/10 pb-6 dark:border-white/10'>
                    <Heading level={2} className="mb-4">Servers {this.renderServerCount()}</Heading>
                </div>
                <Alert open={this.state.openAlert} onClose={
                    () => {
                        this.setState({ openAlert: false });
                    }
                }>
                    <AlertTitle>Are you sure you want to delete this server's settings?</AlertTitle>
                    <AlertDescription>
                        This will delete all the server settings for this server. This action cannot be undone.
                    </AlertDescription>
                    <AlertActions>
                        <Button plain onClick={() => {
                            this.setState({ openAlert: false });
                        }}>
                            Cancel
                        </Button>
                        <Button disabled={this.state.disableButton} onClick={async () => {
                            this.setState({ disableButton: true });
                            await server.delete("/dashboard/servers/" + this.props.bot_id + "/" + this.state.deleteId);
                            this.setState({
                                servers: this.state.servers.filter(server => server.id !== this.state.deleteId),
                                deleteId: null,
                                openAlert: false,
                                disableButton: false
                            });
                        }}>Delete</Button>
                    </AlertActions>
                </Alert>
                {
                    this.state.loaded ? <>

                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableHeader>Id</TableHeader>
                                    <TableHeader>Last Update</TableHeader>
                                    <TableHeader>Action</TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {this.renderServers()}

                            </TableBody>
                        </Table></> :
                        <>
                            <div className="flex justify-center items-center mt-20 h-5 bg-zinc-900">
                                <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-white"></div>
                            </div>
                        </>
                }
            </Dashboard>
        );
    }
}

const mapStateToProps = (state) => ({
    modules: state.data.modules,
    bot_id: state.data.bot.id,
    bot: state.data.bot ? state.data.bot : {}
});

const mapDispatchToProps = {
    updateBotSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(Servers);