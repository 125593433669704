import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/table';
import { Button } from '../components/button';
import { updateBotSettings } from '../actions';

export class ModuleSettings extends Component {

    renderModules = () => {
        var modules = [];
        this.props.modules.forEach(module => {
            modules.push(
                <TableRow key={module.id}>
                    <TableCell className="font-medium">{module.name}</TableCell>
                    <TableCell>{module.id}</TableCell>
                    <TableCell className="text-zinc-500">
                        {this.props.moduleSettings[module.id]?.enabled == false ? <Button color="blue">Enable</Button> : <Button onClick={() => {
                            var moduleSettings = { ...this.props.moduleSettings };
                            moduleSettings[module.id] = { enabled: false };
                            this.props.updateBotSettings("module_settings", moduleSettings);
                        }} >Disable</Button>}
                    </TableCell>
                </TableRow>
            );
        });


        return modules;
    };
    render() {
        return (
            <Dashboard header="Bot Modules">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeader>Name</TableHeader>
                            <TableHeader>Id</TableHeader>
                            <TableHeader>Action</TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {this.renderModules()}

                    </TableBody>
                </Table>
            </Dashboard>
        );
    }
}

const mapStateToProps = (state) => ({
    modules: state.data.modules,
    moduleSettings: state.data.bot.module_settings ? state.data.bot.module_settings : {},
});

const mapDispatchToProps = {
    updateBotSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleSettings);