import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import { Subheading } from '../components/heading';
import { Avatar } from '../components/avatar';
import { Badge } from '../components/badge';
import { Divider } from '../components/divider';
import { Heading } from '../components/heading';
import { Select } from '../components/select';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/table';
export function Stat({ title, value, change }) {
    return (
        <div>
            <Divider />
            <div className="mt-6 text-lg/6 text-white font-medium sm:text-sm/6">{title}</div>
            <div className="mt-3 text-3xl/8 text-white  font-semibold sm:text-2xl/8">{nFormatter(value, 1)}</div>
            <div className="mt-3 text-sm/6 sm:text-xs/6">
                <Badge color={change.startsWith('+') ? 'lime' : 'pink'}>{change}</Badge>{' '}
                <span className="text-zinc-500">from last week</span>
            </div>
        </div>
    );
}

const nFormatter = (num, digits) => {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
        .slice()
        .reverse()
        .find(function (item) {
            return num >= item.value;
        });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
};
const orders = [];

export class Home extends Component {


    render() {
        return (
            <Dashboard>

                <>
                    <Heading>Welcome Back</Heading>
                    <div className="mt-8 flex items-end justify-between">
                        <Subheading>Overview</Subheading>
                        <div>
                            <Select name="period">
                                <option value="last_week">Last week</option>
                                <option value="last_two">Last two weeks</option>
                                <option value="last_month">Last month</option>
                                <option value="last_quarter">Last quarter</option>
                            </Select>
                        </div>
                    </div>
                    <div className="mt-4 grid gap-8 sm:grid-cols-2 xl:grid-cols-4">
                        <Stat title="Pageviews" value={this.props.stats.pageviews} change="+0%" />
                        <Stat title="Total servers" value={this.props.stats.server_count} change="+0%" />
                        <Stat title="Total users" value={this.props.stats.server_count} change="+0%" />
                        <Stat title="New servers" value={this.props.stats.server_count} change="+0%" />
                    </div>
                    {/* <Subheading className="mt-14">Recent orders</Subheading>
                    <Table className="mt-4 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                        <TableHead>
                            <TableRow>
                                <TableHeader>Order number</TableHeader>
                                <TableHeader>Purchase date</TableHeader>
                                <TableHeader>Customer</TableHeader>
                                <TableHeader>Event</TableHeader>
                                <TableHeader className="text-right">Amount</TableHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders.map((order) => (
                                <TableRow key={order.id} href={order.url} title={`Order #${order.id}`}>
                                    <TableCell>{order.id}</TableCell>
                                    <TableCell className="text-zinc-500">{order.date}</TableCell>
                                    <TableCell>{order.customer.name}</TableCell>
                                    <TableCell>
                                        <div className="flex items-center gap-2">
                                            <Avatar src={order.event.thumbUrl} className="size-6" />
                                            <span>{order.event.name}</span>
                                        </div>
                                    </TableCell>
                                    <TableCell className="text-right">US{order.amount.usd}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table> */}
                </>

            </Dashboard >
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.data.user,
    stats: state.data.stats,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);